export default [] = getMenu(localStorage.getItem("RoleId"));

function getMenu(roleId) {
  let menu = [
    {
      title: "Information Panel",
      icon: "HomeIcon",
      route: "Home",
    },
    {
      title: "Clients",
      icon: "UsersIcon",
      route: "Clients",
    },
    {
      title: "Matter",
      icon: "BriefcaseIcon",
      route: "Matter",
    },
    {
      title: "Finance",
      icon: "DollarSignIcon",
      children: [
        {
          title: "Invoice",
          route: "Invoice",
        },
        {
          title: "Payment",
          route: "Payment",
        },
      ]
    },
    {
      title: "Reports",
      icon: "BookOpenIcon",
      children: [
        {
          title: "Matter",
          children: [
            {
              title: "MatterReport",
              route: "MatterReport",
            },
            {
              title: "LawyerDailyworkReport",
              route: "LawyerDailyworkReport",
            },
          ],
        },
        {
          title: "Finance",
          children: [
            {
              title: "CreditNotes",
              route: "CreditNotes",
            },
            {
              title: "CancelledInvoice",
              route: "CancelledInvoice",
            },
            {
              title: "CostAccounting",
              route: "CostAccounting",
            },
            {
              title: "Vouchers",
              route: "Vouchers",
            },
            {
              title: "AgingReport",
              route: "AgingReport",
            },

            {
              title: "ExpenseReport",
              route: "ExpenseReport",
            },
          ],
        },
      ]
    },
  ];
  if (roleId == 1 || roleId == 2) {
    menu.push(
      {
        title: "Definitions",
        icon: "AlignCenterIcon",
        children: [
          {
            title: "Employee",
            route: "Employee",
          },
          {
            title: "AgentPriceList",
            route: "LawClient",
          },
          {
            title: "Law Activity",
            route: "LawActivity",
          },
          {
            title: "Law Subject",
            route: "LawSubject",
          },
          {
            title: "Law Decision",
            route: "LawDecision",
          },
          {
            title: "Law Matter Out Come",
            route: "LawMatterOutcome",
          },
          {
            title: "Law Matter File Type",
            route: "LawMatterFileType",
          },
          {
            title: "Law Matter Type",
            route: "LawMatterType",
          },
          {
            title: "Law Matter Status",
            route: "LawMatterStatus",
          },
          {
            title: "Law Court",
            route: "LawCourt",
          },
          {
            title: "Experts",
            route: "Expert",
          },
          {
            title: "Mediators",
            route: "Mediator",
          },
          {
            title: "Opposite Side",
            route: "OppositeSide",
          },
          {
            title: "Opposite Side Attorney",
            route: "OppositeSideAttorney",
          },
        ],
      },
    );
  }
  return menu;
}
