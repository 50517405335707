<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive" v-model="menuIcon">
                    <feather-icon icon="MenuIcon" size="21" ref="menuIcon" />
                </b-link>
            </li>
        </ul>
    
        <b-navbar-nav class="nav align-items-center ml-auto">
            <!--Dil Sekmesi-->
            <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language" right>
                <template #button-content>
                    <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
                    <span class="ml-50 text-body">{{ currentLocale.name }}</span>
                </template>
                <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="changeLanguage(localeObj.locale)">
                    <b-img :src="localeObj.img" height="14px" width="22px" :alt="localeObj.locale" />
                    <span class="ml-50">{{ localeObj.name }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
            <!--Profil Sekmesi-->
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
    
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{name + ' ' + surname}}
                        </p>
                        <span class="user-status">{{title}}</span>
                    </div>
                    <b-avatar size="40" variant="light-primary" badge :src="imageSrc" class="badge-minimal" badge-variant="success" />
                </template>
    
                <b-dropdown-item to="Account" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>{{ $t('others.account') }}</span>
                </b-dropdown-item>
                <b-dropdown-item to="exit" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>{{ $t('others.exit') }}</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
    </template>
    
    <script>
    import {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BImg
    } from 'bootstrap-vue'
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
    import {
        localize
    } from 'vee-validate';
    import moment from 'moment'
    
    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar,
            BImg,
    
            // Navbar Components
            DarkToggler,
        },
        data() {
            return {
                isHidden: null,
                menuIcon: false,
                locale: this.$i18n.locale,
                name: '',
                surname: '',
                title: '',
                imageSrc: '',
            }
        },
        mounted() {
            this.name = localStorage.getItem('Name')
            this.surname = localStorage.getItem('Surname')
            this.title = localStorage.getItem('Title')
    
            var defaultlanguage = "tr"
            // if(this.$cookies.get('languageValue') != null)
            //    defaultlanguage = this.$cookies.get('languageValue');
            if (sessionStorage.getItem('languageValue') != null)
                defaultlanguage = sessionStorage.getItem('languageValue');
            this.changeLanguage(defaultlanguage);
    
            if (localStorage.getItem('employeePhoto') != "")
                this.imageSrc = 'data:image/png;base64,' + localStorage.getItem('employeePhoto')
            else
                this.imageSrc = require('@/assets/images/logoUser.jpeg')
    
        },
        methods: {
            openExchangeRateModal() {
                this.$refs.exchangeRateCalculatorForm.showInfo()
            },
            openTotalImageUpload() {
                this.$refs.totalImageUploadForm.showInfo()
            },
            changeLanguage(value) {
                this.$i18n.locale = value;
                sessionStorage.setItem("language", value)
                localize(value);
                // localStorage.language = value
                sessionStorage.setItem('languageValue', value)
                //this.$cookies.set('languageValue', value)
            },
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {},
            },
        },
        computed: {
            currentLocale() {
                return this.locales.find(l => l.locale === this.$i18n.locale)
            },
        },
        setup() {
            /* eslint-disable global-require */
            const locales = [{
                    locale: 'tr',
                    img: require('@/assets/images/flags/tr.png'),
                    name: 'Türkçe',
                },
                {
                    locale: 'en',
                    img: require('@/assets/images/flags/en.png'),
                    name: 'English',
                },
            ]
            /* eslint-disable global-require */
    
            return {
                locales,
            }
        },
    }
    </script>
    
    <style lang="scss">
     .base-timer[data-v-f673a68c] {
         position: relative;
         width: 40px;
         height: 40px;
     }
    
     .base-timer__label[data-v-f673a68c] {
         position: absolute;
         width: 45px;
         height: 40px;
         top: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         font-size: 12px;
     }
    </style>
    